<nb-card class="container">
    <nb-card-header>
        <h4>{{connector.connector_id === IMPORT_LDAP_ID ? 'Import manuel des utilisateurs' : connector.name}}</h4>
    </nb-card-header>
    <nb-card-body>
        <form [formGroup]="form">
            <div *ngIf="alertLevel">
                <h6> Niveau </h6>
                <ng-container *ngIf="!niveauToAlert;else niveauTemplate">
            <nb-select [(selected)]="options[+niveauToAlert]"  class="dropdown"
                formControlName="alertLevel" placeholder="choisissez un niveau"
                (selectedChange)="onChange($event)"
                [status]="status"
            >
                <nb-option *ngFor="let option of options" value="{{option}}">{{option}}</nb-option>
            </nb-select>
          </ng-container>
          <ng-template #niveauTemplate>
            <button nbButton disabled>{{options[+niveauToAlert]}}</button>
          </ng-template>
<!--            <br><br>-->
<!--            <div class="sms_header">-->
<!--                <h5> Texte à afficher en début de SMS </h5>-->
<!--                <nb-select selected="" class="dropdown"-->
<!--                    formControlName="sms_header" placeholder="ajoutez une entête au message"-->
<!--                    (selectedChange)="onChange($event)"-->
<!--                    [status]="status"-->
<!--                >-->
<!--                    <nb-option *ngFor="let option of smsHeaderOptions" value="{{option}}">{{option}}</nb-option>-->
<!--                </nb-select>-->

<!--            </div>-->
            </div>
            <div *ngIf="connector.connector_id !== alertUserId">
                <div class="title">
                    <h6>sauvegardes :</h6>
                    <br>
                    <p style="margin-top: 0.4rem;">(Une nouvelle sauvegarde sera automatiquement créée lors de l'import depuis la sauvegarde sélectionnée)</p>
                </div>
                <div formArrayName="depends_on">
                    <!-- the following controls here(services_to_launch attribute) stands for the list of formGroups -->
                    <div [formGroupName]="i" *ngFor="let item of form.controls?.depends_on?.controls; let i = index" class="dropdownParent">
                        <span class="service_name">
                            <!-- <nb-checkbox checked formControlName="checked" class="checkbox">{{ depends_on[i].service_id }} : </nb-checkbox> -->
                            {{ depends_on[i].service_id }} :
                        </span>
                        <!-- <label *ngIf="teamLead.get('service_id')?.hasError('required')" class="error">Name required. </label> -->
                        <nb-select class="dropdown" formControlName="snapshot" placeholder="Choisissez une sauvegarde">
                            <nb-option *ngFor="let snapshot of depends_on[i].snapshots" value="{{snapshot.snapshot_id}}">
                                {{snapshot.create_date | date:"dd/MM/yy HH:mm:ss"}}
                                <span *ngIf="snapshot.snapTags.length">
                                    (<span *ngFor="let tag of snapshot.snapTags">{{tag.name}}</span>)
                                </span>
                            </nb-option>
                        </nb-select>
                    </div>
                </div>
            </div>
            <div *ngIf="connector.connector_id == IMPORT_LDAP_ID">
                <div class="comment">Commentaire :
                    <input type="text" nbInput fullWidth shape="semi-round" placeholder="Commentaire pour la sauvegarde qui sera généré"
                        formControlName="comment">
                </div>
                <div class="mails">
                    <div class="checkbox">
                        <nb-checkbox checked formControlName="sendMail" (change)="toggleRecipients()">Envoyer l'e-mail de synthèse</nb-checkbox>
                    </div>
                    <div [ngClass]="{'disabled': !form.get('sendMail').value}">
                        <span>Adresses e-mail des destinataires </span>
                        <em> (Appuyez sur Entrée pour ajouter un destinataire.)
                            <span class="text-danger" *ngIf="recipientsCheckBoxValue"> *</span>
                        </em> :
                        <nb-tag-list (tagRemove)="onTagRemove($event)">
                            <nb-tag *ngFor="let recipientControl of recipientsControlValue.controls; let i = index"
                                    [text]="recipientControl.value"
                                    removable>
                            </nb-tag>
                            <input type="text" nbTagInput (tagAdd)="onTagAdd($event)" fullWidth shape="semi-round">
                        </nb-tag-list>
                        <!-- <input type="text" nbInput fullWidth shape="semi-round" placeholder="Entrez les adresses e-mail des destinataires, séparées par des virgules"
                             formControlName="mails"> -->
                    </div>
                </div>
            </div>
        </form>
        </nb-card-body>
        <nb-card-footer class="btn-class">
            <button class="cancel" nbButton status="danger" (click)="cancel()">Annuler</button>
            <button class="valider" nbButton status="primary" (click)="submit()" [disabled]="form.invalid || isFormInvalidForConnector()" [ngClass]="{'disabled': form.invalid || isFormInvalidForConnector()}">Valider</button>
        </nb-card-footer>
</nb-card>
