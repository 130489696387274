import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from "@nebular/theme";

@Component({
  selector: 'ngx-user-details-dialog',
  templateUrl: './user-details-dialog.component.html',
  styleUrls: ['./user-details-dialog.component.scss']
})
export class UserDetailsDialogComponent implements OnInit {

  @Input() user:any;
  @Input() alert: Function;

  constructor(protected ref: NbDialogRef<UserDetailsDialogComponent>) { }

  ngOnInit(): void {

  }

  showAlert(user: any) {

    this.alert(user); // Call the alert method passed from parent component
  }

  close() {
    this.ref.close();
  }
}
