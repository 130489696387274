<nb-card>
  <nb-card-header class="title">
    <h2>Utilisateurs de survie</h2>
  </nb-card-header>
  <nb-card-body>
    <ng-container *ngIf="filteredUsers; else noDataTemplate">
        <div class="filters-container">
          <div class="filters">
            <span>
              <input type="text" nbInput fullWidth class="search-input"
                     placeholder="Rechercher des utilisateurs..."
                     [(ngModel)]="searchText" (input)="applyFilter()">
            </span>
            <ng-container *ngIf="!mobileSize;else filterGroup">
              <span>
                <input type="text" nbInput fullWidth class="search-input"
                       placeholder="Rechercher par département..."
                       [(ngModel)]="searchDepartment" (input)="applyFilter()">
              </span>
              <span>
                <nb-select status="primary" class="filter-admin" placeholder="Admin" [(selected)]="selectedAdmin"
                           (selectedChange)="onAdminChange($event)">
                  <nb-option value="none">Tous</nb-option>
                  <nb-option value="1">Admin </nb-option>
                  <nb-option value="2">Non-Admin</nb-option>
                </nb-select>

              </span>
              <span>
                <nb-select status="primary" class="filter-activation" placeholder="Activation" [(selected)]="selectedActivation"
                           (selectedChange)="onActivationChange($event)">
                  <nb-option value="none">Tous</nb-option>
                  <nb-option value="1">Activé</nb-option>
                  <nb-option value="2">Désactivé</nb-option>
                </nb-select>
              </span>
<!--              <span *ngIf="selectedNiveaux !== 'none' && selectedNiveaux !== ''">-->
<!--                <button nbButton (click)="openAlertPopup()" class="alerter-button">Alerter</button>-->
<!--              </span>-->
              <span>
              <span>
                <nb-select status="primary" class="filter-niveau" placeholder="Niveaux" [(selected)]="selectedNiveaux"
                           (selectedChange)="onNiveauChange($event)">
                  <nb-option value="none">Tous les niveaux</nb-option>
                  <nb-option value="0">Niveau 0</nb-option>
                  <nb-option value="1">Niveau 1</nb-option>
                  <nb-option value="2">Niveau 2</nb-option>
                  <nb-option value="3">Niveau 3</nb-option>
                </nb-select>
              </span>
                <nb-select status="primary" class="filter-notification" placeholder="Notification" [(selected)]="selectedNotification"
                           (selectedChange)="onNotifChange($event)">
                  <nb-option value="none">Tous</nb-option>
                  <nb-option value="1">Notifié</nb-option>
                  <nb-option value="2">Non notifié</nb-option>
                </nb-select>

              </span>
    <!--          <span>-->
    <!--            <button nbButton (click)="resetAllUsersTries()" class="alerter-button">-->
    <!--              Réinitialiser les tentatives-->
    <!--            </button>-->
    <!--          </span>-->

            </ng-container>
            <ng-template #filterGroup>
              <span class="filter-mobile">
                <button nbButton nbPopoverPlacement="bottom" [nbPopover]="filterTemplate" *ngIf="mobileSize">Filtres</button>
<!--                <button nbButton (click)="openAlertPopup()" *ngIf="selectedNiveaux !== 'none' && selectedNiveaux !== ''"-->
<!--                        class="alerter-button">Alerter-->
<!--                </button>-->
              </span>
    <!--          <span>-->
    <!--            <button nbButton (click)="resetAllUsersTries()" class="alerter-button template-container">-->
    <!--              Réinitialiser les tentatives-->
    <!--            </button>-->
    <!--          </span>-->

            </ng-template>
            <ng-template #filterTemplate>
              <div class="filter-template">
                <input type="text" nbInput fullWidth class="search-input"
                       shape="semi-round" placeholder="Rechercher par département..."
                       [(ngModel)]="searchDepartment" (input)="applyFilter()">
              </div>
              <div class="filter-template">
                <nb-select status="primary" class="filter-admin" placeholder="Admin" [(selected)]="selectedAdmin"
                           (selectedChange)="onAdminChange($event)">
                  <nb-option value="none">Tous</nb-option>
                  <nb-option value="1">Admin</nb-option>
                  <nb-option value="2">Non Admin</nb-option>
                </nb-select>
              </div>
              <div class="filter-template">
                <nb-select status="primary" class="filter-activation" placeholder="Activation" [(selected)]="selectedActivation"
                           (selectedChange)="onActivationChange($event)">
                  <nb-option value="none">Tous</nb-option>
                  <nb-option value="1">Activé</nb-option>
                  <nb-option value="2">Désactivé</nb-option>
                </nb-select>
              </div>
              <div class="filter-template">
                <nb-select status="primary" class="filter-niveau" placeholder="Niveaux" [(selected)]="selectedNiveaux"
                           (selectedChange)="onNiveauChange($event)">
                  <nb-option value="none">Tous les niveaux</nb-option>
                  <nb-option value="0">Niveau 0</nb-option>
                  <nb-option value="1">Niveau 1</nb-option>
                  <nb-option value="2">Niveau 2</nb-option>
                  <nb-option value="3">Niveau 3</nb-option>
                </nb-select>
              </div>
              <div class="filter-template">
                <nb-select status="primary" class="filter-notification" placeholder="Notification" [(selected)]="selectedNotification"
                           (selectedChange)="onNotifChange($event)">
                  <nb-option value="none">Tous</nb-option>
                  <nb-option value="1">Notifié</nb-option>
                  <nb-option value="2">Non notifié</nb-option>
                </nb-select>
              </div>
            </ng-template>
          </div>
          <div class="table-size-selector">
            <span>Affichage de {{paginationSettings.totalItems == 0 ? 0 : (paginationSettings.currentPage*selectedSize) - selectedSize + 1}}-{{(paginationSettings.totalItems/(selectedSize*paginationSettings.currentPage)) >= 1 ? paginationSettings.currentPage*selectedSize : paginationSettings.totalItems%(selectedSize*paginationSettings.currentPage)}} utilisateurs. <b>Total: {{ paginationSettings.totalItems }}</b></span>
            <div class="select-container">
              <nb-select status="primary" placeholder="Nombre de lignes" (selectedChange)="onTableSizeChange($event)" [(selected)]="selectedSize">
                <nb-option *ngFor="let size of tableSize" [value]="size">
                  {{ size }}
                </nb-option>
              </nb-select>
            </div>
          </div>

        </div>
        <div>
          <table class="table table-striped" id="myTable">
            <thead>
            <tr>
              <th>Prénom Nom <span class="info-icon"><i class="fa fa-question-circle" [nbPopover]="popoverContent"
                nbPopoverTrigger="hint" nbPopoverPlacement="top" aria-hidden="true"></i></span></th>
                <ng-template #popoverContent>
                  <i class="fa fa-star" aria-hidden="true"></i> <span class="popoverContent">pour les utilisateurs admin</span>
                </ng-template>
              <!--          <th>Identifiant</th>-->
              <th>Email</th>
              <!--                    <th>Téléphone</th>-->
              <!--                    <th>Département</th>-->
              <!--                    <th>Poste</th>-->
              <th class="text-center">Activé <span class="info-icon"><i class="fa fa-question-circle" [nbPopover]="popover"
                nbPopoverTrigger="hint" nbPopoverPlacement="top" aria-hidden="true"></i></span></th>
                <ng-template #popover class="popoverActive">
                  <div class="popoverItem">
                    <i class="fas fa-lock" style="color: red" aria-hidden="true"></i>
                    <span>Compte bloqué</span>
                  </div>
                  <div class="popoverItem">
                    <i class="fa fa-times" style="color: red" aria-hidden="true"></i>
                    <span>Compte désactivé</span>
                  </div>
                  <div class="popoverItem">
                    <i class="fa fa-check" style="color: green" aria-hidden="true"></i>
                    <span>Compte activé</span>
                  </div>
                </ng-template>
              <th class="text-center">Niveau</th>
              <th class="text-center">Notifié</th>
              <th class="text-center">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of filteredUsers | paginate: paginationSettings; let i = index">
              <td label="Prénom">{{ user.firstName }} {{ user.lastName }}<span class="star-icon" *ngIf="user.admin"><i class="fa fa-star" nbPopover="Admin"
                nbPopoverTrigger="hint" nbPopoverPlacement="top" aria-hidden="true"></i></span></td>

          <!--                        <td>{{user.username}}</td>-->
          <td label="Email">{{ user.email }}</td>
          <!--                        <td>{{user.phoneNumber}}</td>-->
          <!--                        <td>{{user.jobDepartment}}</td>-->
          <!--                        <td>{{user.jobTitle}}</td>-->
          <td [ngClass]="{ 'text-center': !mobileSize }" label="Activé">
            <i class="fa" [ngClass]="{'fa fa-unlock blocked-disabled': user.blocked == '1','fa fa-check alerted': user.enabled, 'fas fa-times alertedWithError': !user.enabled}"
               [title]="user.blocked == '1' ? 'Compte bloqué' : user.enabled ? 'Compte activé' : 'Compte désactivé'">
            </i>
          </td>
          <td [ngClass]="{ 'text-center': !mobileSize }" label="SMS exercice">
            <span *ngIf="user.alert_level == 1">Exercice</span>
            <span *ngIf="user.alert_level != 1">{{ user.alert_level }}</span>
          </td>
          <td [ngClass]="{ 'text-center': !mobileSize }" label="Notifié">
            <i [ngClass]="{'fas fa-check alerted': user.alerted,
                                    'fa fa-exclamation alertedWithError': user.alertedWithError,
                                    'fa fa-clock notAlerted': !user.alerted
                                }"
               [title]="user.alerted ? 'Utilisateur notifié' : user.alertedWithError ? 'Erreur, notification mal envoyée' : 'Utilisateur pas encore notifié'">
            </i>
          </td>
          <td class="action-icons" label="Actions">
            <span>
              <i *ngIf="!user.is_alerting" class="fa fa-bell alert-button" nbPopover="Notifier l'utilisateur par sms"
                 nbPopoverTrigger="hint" nbPopoverPlacement="top"
                 (click)="alert(user)">
              </i>
            </span>
            <span *ngIf="user.is_alerting">
              <nb-icon class="nb-email alerting-icon" status="warning"></nb-icon>
            </span>
            <span>
              <i class="fa fa-info-circle alert-button" nbPopover="Plus de détails"
                 nbPopoverTrigger="hint" nbPopoverPlacement="top"
                 (click)="openPopUp(user)">
              </i>
            </span>
            <span>
              <i  class="fa fa-unlock" [ngClass]="{'alert-button': user.blocked == '1','disabled-alert-button': user.blocked == '0'}"
                  [nbPopoverTrigger]="user.blocked == '1' ? 'hint' : 'noop'" nbPopoverPlacement="top" [ngStyle]="{'visibility': !user.blocked ? 'hidden' : 'visible'}"
                  [nbPopover]="user.blocked == '1' ? 'Débloquer le compte' : null" aria-hidden="true" (click)="user.blocked == '1' ? resetUserTries(user) : null"></i>
            </span>
            <!-- <nb-card [nbSpinner]="true" nbSpinnerSize="small" nbSpinnerStatus="primary">
                <nb-card-body>Envoie sms encours</nb-card-body>
            </nb-card> -->
          </td>
        </tr>
        </tbody>
      </table>
      <div>
        <pagination-controls
          previousLabel="Précédent"
          nextLabel="Suivant"
          (pageChange)="onPageChange($event)">
        </pagination-controls>
      </div>

        </div>
    </ng-container>
    <ng-template #noDataTemplate>
      <ng-container *ngIf="!noDataMessage;else errorTemplate">
        <nb-card [nbSpinner]="true" nbSpinnerStatus="primary" nbSpinnerSize="giant">
          <nb-card-body>

          </nb-card-body>
        </nb-card>
      </ng-container>
      <ng-template #errorTemplate>
        <div class="error-block">
          <p>{{noDataMessage.error.message}}</p>
        </div>
      </ng-template>
    </ng-template>
  </nb-card-body>
</nb-card>
