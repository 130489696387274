<tr>
    <td>{{user.firstName}}</td>
    <td>{{user.lastName}}</td>
    <td>{{user.username}}</td>
    <td>{{user.email}}</td>
    <td>{{user.phoneNumber}}</td>
    <td>{{user.jobDepartement}}</td>
    <td>{{user.jobTitle}}</td>
    <td>{{user.alerted}}</td>
    <!-- <td><i class="nb-notifications"></i></td> -->
</tr>