<div class="container">
    <h5>Création d'un nouveau label :</h5>
    <form [formGroup]="form" (submit)="submit()">
         <label><strong>Nom: </strong></label>
        <input type="text" nbInput fullWidth shape="semi-round" placeholder="nom du label" formControlName="name">
        <br>

        <label><strong>Description: </strong></label>
        <textarea rows="3" cols="40" nbInput fullWidth shape="round"  placeholder="Description" id="description" formControlName="description"></textarea>

        <!-- <strong>Le label serait elle système ? </strong>
        <nb-radio-group formControlName="system">
            <nb-radio [value]="true"> Oui </nb-radio>
            <nb-radio [value]="false"> Non </nb-radio>
        </nb-radio-group>        

        <strong>Le label serait elle unique ? </strong>
        <nb-radio-group formControlName="system">
          <nb-radio [value]="'true'"> Oui </nb-radio>
          <nb-radio [value]="false"> Non </nb-radio>
        </nb-radio-group>         -->
        <br>

        <div class="btn-class">
            <button class="cancel" nbButton status="danger" (click)="cancel()">Annuler</button>
            <button class="valider" nbButton status="primary" type="submit" [disabled]="form.invalid" [ngClass]="{'disabled': form.invalid}">Valider</button>
        </div>
    </form> 
</div>
