<div id="myModal">
  <div class="modal-dialog modal-confirm">
      <div class="modal-content">
          <div class="modal-header flex-column">
              <div class="icon-box"  [ngStyle]="{
                'border-color': headerIcon === 'fa-check' ? '#60c7c1' : (headerIcon === 'fa-info' ? '#36a0e7' : '') 
              }">
                  <i class="fa {{headerIcon}}" aria-hidden="true" [ngStyle]="{
                    'color': headerIcon === 'fa-check' ? '#60c7c1' : (headerIcon === 'fa-info' ? '#36a0e7' : '') 
                  }"></i>
              </div>
              <h4 class="modal-title w-100" style="color: {{color}};">{{ title }}</h4>
          </div>
          <div class="modal-body">
              <p class="paragraph">{{message}}</p>
          </div>
          <div class="modal-footer" [ngClass]="{'justify-content-center': !navigateToUsersButton, 'justify-content-between': navigateToUsersButton}">
              <button type="button" class="btn btn-secondary"  data-dismiss="modal" (click)="dismiss()">Fermer</button>
              <button type="button" class="btn btn-secondary" (click)="dismiss()" *ngIf="navigateToUsersButton" (click)="navigateToUsersPage()">Utilisateurs</button>
          </div>
      </div>
  </div>
</div>