<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <img src="assets/images/logo_krisalee.png" alt="" width="100" height="60" class="logo_img">
    <a class="logo" href="#" (click)="navigateHome()">{{ organization ? organization.name : 'krisalee' }}</a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>
<div class="header-container">
  <div class="status-card-container">
      <ngx-status-card-2 [title]="emergencyStatusCard.title" [type]="emergencyStatusCard.type">
        <i [ngClass]="emergencyStatusCard.iconClass"></i>
      </ngx-status-card-2>
  </div>
  <nb-actions size="small">

    <!-- <nb-action class="control-item"> -->
      <!-- <nb-search type="rotate-layout"></nb-search> -->
    <!-- </nb-action> -->
    <!-- <nb-action class="control-item" icon="email-outline"></nb-action> -->
    <!-- <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [picture]="user?.picture"
               [name]="this.fullName"
               [nbContextMenuTag]="tag"
               >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
