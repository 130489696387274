<nb-card>
  <nb-card-header class="title">
    <div class="header-content">
      <span>Détails d'utilisateur</span>
    </div>

  </nb-card-header>
  <nb-card-body>
    <div class="profile-columns">
      <div class="column">
        <div class="profile-row">
          <nb-icon icon="person-outline"></nb-icon>
          <span>{{ user.firstName }} {{ user.lastName }}</span>
        </div>
        <div class="profile-row">
          <nb-icon icon="globe-outline"></nb-icon>
          <span>{{ user.jobDepartment }}</span>
        </div>
        <div class="profile-row">
          <nb-icon icon="email-outline"></nb-icon>
          <span>{{ user.email }}</span>
        </div>
        <div class="profile-row">
          <nb-icon icon="alert-triangle-outline"></nb-icon>
          <span>
            <span>Niveau : {{user.alert_level}}</span>
          </span>
        </div>

      </div>
      <div class="column">
        <div class="profile-row">
          <nb-icon icon="person-done-outline"></nb-icon>
          <span>{{ user.username }}</span>
        </div>

        <div class="profile-row">
          <nb-icon icon="award-outline"></nb-icon>
          <span>{{ user.jobTitle }}</span>
        </div>

        <div class="profile-row">
          <nb-icon icon="phone-outline"></nb-icon>
          <span>{{ user.phoneNumber }}</span>
        </div>
        <div class="profile-row">
          <nb-icon
            [icon]="user.alerted ? 'checkmark-circle-outline' : user.alertedWithError ? 'alert-circle-outline' : 'clock-outline'"
            [status]="user.alerted ? 'success' : user.alertedWithError ? 'danger' : 'warning'"></nb-icon>
          <span>{{ user.alerted ? 'Utilisateur notifié' : user.alertedWithError ? 'Erreur, alerte mal envoyée' : 'Utilisateur pas encore notifié' }}</span>
        </div>

      </div>
    </div>
    <div class="notify-button">
      <button class="btn" (click)="showAlert(user)">Notifier</button>
    </div>
  </nb-card-body>

</nb-card>
