<!-- <div *ngIf="iscreating || isdeleting" [ngClass]="{'container1': iscreating, 'isdeleting': isdeleting}"> -->
<!-- <div *ngIf="iscreating && !service_status || isdeleting && service_status" class="container1"> -->

<div class="container">
    <div *ngIf="connector.is_running" class="container1">
        <div class="load">
            <div class="load__bar load__bar--1"></div>
            <div class="load__bar load__bar--2"></div>
            <div class="load__bar load__bar--3"></div>
            <div class="load__bar load__bar--4"></div>
            <div class="load__bar load__bar--5"></div>
        </div>
    </div>
    <div class="row nomargin">
        <div class="col-sm-12 col-md-5 productName">
            <!-- <span [ngClass]="{'started': true, 'stopped': false}">
                <i class="fa fa-circle"></i>
            </span> -->
            <span>
                <i class="fa fa-play pointer launchIcon" (click)="launch.emit()" title="{{connector.description}}"></i>
            </span>
            <strong>{{connector.name}}</strong>
        </div>
        <div class="col-sm-5 col-md-7">
            <div class="row">
                <div class="col-sm-7 col-md-8">
                    <span  *ngIf="connector.last_run_datetime">
                        Dernière exécution : {{connector.last_run_datetime | date:"dd/MM/yy HH:mm"}}
                        <i [ngClass]="{'fas': true, 'fa-times error': !(connector.last_run_status), 'fa-check success': connector.last_run_status}"
                        [title]="connector.last_run_status ? 'Dernière exécution réussie' : 'Dernière exécution en échec'">
                        </i>
                        <!-- <span *ngIf="connector.last_run_status===1 || connector.last_run_status===0">=> {{getstatusString(connector.last_run_status)}}</span> -->
                    </span>
                </div>
<!--                <div class="col-sm-2 col-md-4 d-flex justify-content-end" *ngIf="connector.connector_id == alertUserId">-->
<!--                </div>-->
                <div class="col-sm-2 col-md-4" *ngIf="connector.connector_id == alertUserId">
                  <div class="flex">
                    <i (click)="navigateToUserRoute()" class="fas fa-users pointer iconAnimation users-hidden" title="Aller vers la page Utilisateurs"></i>
                    <i (click)="navigateToUserRoute()" class="fas fa-users pointer iconAnimation users-hidden" title="Aller vers la page Utilisateurs"></i>
                    <i (click)="navigateToUserRoute()" class="fas fa-users pointer iconAnimation users-hidden" title="Aller vers la page Utilisateurs"></i>
                    <i (click)="navigateToUserRoute()" class="fas fa-users pointer iconAnimation" title="Aller vers la page Utilisateurs"></i>
                  </div>
                </div>
                <div class="col-sm-2 col-md-4" *ngIf="connector.connector_id == IMPORT_LDAP_ID">
                    <div class="flex" *ngIf="connector.connector_id != alertUserId">
                        <i *ngIf="reports?.length" class="fas fa-file-alt pointer iconAnimation" (click)="showReports()" title="Rapports d'import des utilisateurs"></i>
                        <i class="fa fa-eraser pointer iconAnimation" (click)="purgeReports()" aria-hidden="true" title="Nettoyer les rapports"></i>
                        <i class="fa fa-cogs pointer iconAnimation" aria-hidden="true" title="Mettre à jour la liste des destinataires du mail de synthèse" (click)="updateRecipients.emit()"></i>
                        <i class="fas fa-upload pointer iconAnimation"
                            title="Sélectionner un fichier CSV des utilisateurs à importer" (click)="uploadFile()"
                        ></i>
                    </div>
                </div>
                <!-- <div class="col-1">
                    <nb-icon icon="more-horizontal-outline" (click)="planify()"></nb-icon>
                </div> -->
            </div>
        </div>
    </div>
    <!-- <div class="row description" *ngIf="connector.description">
        {{connector.description}}
    </div> -->
    <div class="row logs" *ngIf="logs?.length">
        <ngx-log [logs]="logs" class="col-12"></ngx-log>
    </div>
</div>
